import { apiAuthList, apiReportLogInsertOpenLog } from '@/request/api';
import { reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export default (information) => {
    const $router = useRouter();

    const query = reactive({
        pageNum: 1,
        pageSize: information === 'Y' ? 4 : 5
    })
    const total = ref(0);
    const list = ref([]);
    // 需要带reportId的报告path
    const reportIdPaths = ['/assistant', '/egpm', '/egpm/material']

    // 获取列表
    async function getList() {
        const { data } = await apiAuthList(query);
        total.value = data?.total || 0;
        list.value = data?.list || [];
    }

    // 分页
    watch(() => query.pageNum, getList);

    // 点击报告
    function onReport(row) {
        if (!row.path) return;
        apiReportLogInsertOpenLog({ path: row.path, title: row.name, reportId: row.id });
        const configs = { path: row.path };
        if (reportIdPaths.includes(row.path)) {
            configs.query = { reportId: row.id };
        }
        if (row.reportType === 'POWERBI') {
            configs.query = { reportId: row.id, name: row.name };
        }
        const { href } = $router.resolve(configs);
        window.open(href, "_blank");
    }

    getList();

    return { list, total, query, onReport }
}